import { Card, LayerGroup } from "@opusinsights/ui";
import { clone, remove, upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import SingleAreaMap from "../../../map/SingleAreaMap";
import { Events, LayerData } from "../../../map/SingleAreaMap/interfaces";

/**
 * The props for the layer selection cards
 */
interface LayerSelectionProps {
  map: SingleAreaMap;
}

/**
 * The shape of the layers to be displayed
 */
type Layers = Array<{
  id: string;
  name: string;
  active: boolean;
  legend: string | React.ReactNode;
}>;

/**
 * The shape of the layers when sorted into buckets
 */
type SortedLayers = Array<{
  group: string;
  layers: Layers;
}>;

/**
 * Which layers should go into which buckets
 */
const layerGroups = {
  imagery: [
    "imagery",
    "multispectral",
    "nir",
    "satellite"
  ],
  environment: [
    "biomass",
    "erosion",
    "floodrisk",
    "protectedforest",
    "protectedpark",
    "rivers",
    "swamp",
    "waterlogging",
    "waterlogging_risk"
  ],
  topography: [
    "contourlines",
    "dsm",
    "dtm",
    "objectheightmodel",
    "slope"
  ],
  infrastructure: [
    "airport",
    "buildings",
    "bungalows",
    "cables",
    "cemetery",
    "church",
    "drains",
    "factory",
    "hospital",
    "mosque",
    "offices",
    "pipelines",
    "pond",
    "powerlines",
    "roads",
    "school",
    "telephonetower"
  ],
  farm: [
    "blocks",
    "cropcircles",
    "croprows",
    "ditches",
    "farmblocks",
    "grvi",
    "hectareblocks",
    "missingtrees",
    "nursery",
    "ndre",
    "ndvi",
    "newly_planted_palm",
    "parcelas",
    "plantablearea",
    "recovered",
    "spaces_to_plant",
    "treecount",
    "vari",
    "vegetationindex",
    "youngtrees"
  ],
  soilmap: [
    "boron",
    "bulkdensity",
    "ca",
    "calcium",
    "compostdry",
    "copper",
    "cu",
    "fe",
    "ferrum",
    "k",
    "liming",
    "magnesium",
    "manganese",
    "mg",
    "mn",
    "na",
    "om",
    "organicmatter",
    "p",
    "phosphorus",
    "potassium",
    "ph",
    "s",
    "sodium",
    "soilph",
    "sulphur",
    "zn",
    "zinc",
  ],
};

/**
 * The layer selection component which shows the cards with the layers the user can access
 *
 * @param props The props the component expects
 */
function LayerSelection(props: LayerSelectionProps) {
  const { t } = useTranslation();
  const [layers, setLayers] = useState<Layers>([]);
  const [sortedLayers, setSortedLayers] = useState<SortedLayers>([]);

  useEffect(() => {
    let mounted = true;

    function parseLayers(layers: Array<LayerData>): Layers {
      const newLayers: Layers = [];

      for (const layer of layers) {
        // Show the legend as a inline base64 image if there is one
        let legend: string | React.ReactNode = "Loading legend...";
        if (layer.legend === false) {
          legend = "Unable to load legend";
        } else if (layer.legend !== undefined) {
          legend = <img src={`data:image/png;base64,${layer.legend}`} alt="" />;
        }

        let name = upperFirst(layer.name);
        if (i18n.exists(`layers.${layer.name}`)) {
          name = t(`layers.${layer.name}`);
        }

        newLayers.push({
          id: layer.id,
          name: name,
          active: layer.active,
          legend: legend,
        });
      }

      return newLayers;
    }

    setLayers(parseLayers(props.map.getLayers()));

    // Listen to various events which let us know that the layer selection has to be updated
    props.map.emitter.on(Events["timeseries:changed"], () => {
      if (mounted) {
        setLayers(parseLayers(props.map.getLayers()));
      }
    });

    props.map.emitter.on(Events["layers:changed"], () => {
      if (mounted) {
        setLayers(parseLayers(props.map.getLayers()));
      }
    });

    props.map.emitter.on(Events["legends:loaded"], () => {
      if (mounted) {
        setLayers(parseLayers(props.map.getLayers()));
      }
    });

    return () => {
      mounted = false;
    };
  }, [props.map, t]);

  useEffect(() => {
    const initialSorted: { [type: string]: Layers } = {};
    const clonedLayers = clone(layers);

    // Sort the various layers into the correct groups
    initialSorted.imagery = remove(
      clonedLayers,
      (n) => layerGroups.imagery.indexOf(n.id) !== -1
    );
    initialSorted.environment = remove(
      clonedLayers,
      (n) => layerGroups.environment.indexOf(n.id) !== -1
    );
    initialSorted.topography = remove(
      clonedLayers,
      (n) => layerGroups.topography.indexOf(n.id) !== -1
    );
    initialSorted.infrastructure = remove(
      clonedLayers,
      (n) => layerGroups.infrastructure.indexOf(n.id) !== -1
    );
    initialSorted.farm = remove(
      clonedLayers,
      (n) => layerGroups.farm.indexOf(n.id) !== -1
    );
    initialSorted.soilmap = remove(
      clonedLayers,
      (n) => layerGroups.soilmap.indexOf(n.id) !== -1
    );
    initialSorted.other = clonedLayers;

    const sorted: SortedLayers = [];

    // Put the sorted layers into a array, making sure to put other at the end
    for (const [name, groupLayers] of Object.entries(initialSorted)) {
      if (groupLayers.length > 0 && name !== "other") {
        sorted.push({
          group: i18n.exists(`layerGroups.${name}`)
            ? t(`layerGroups.${name}`)
            : upperFirst(name),

          layers: groupLayers,
        });
      }
    }

    if (initialSorted.other.length > 0) {
      sorted.push({
        group: i18n.exists(`layerGroups.other`)
          ? t(`layerGroups.other`)
          : upperFirst("other"),
        layers: initialSorted.other,
      });
    }

    setSortedLayers(sorted);
  }, [layers, t]);

  function clickLayer(layer: string) {
    props.map.toggleLayer(layer);
  }

  return (
    <React.Fragment>
      {sortedLayers.map((sorted) => (
        <Card
          title={sorted.group}
          marginTop="medium"
          key={sorted.group}
          canBeMinimized={true}
        >
          <LayerGroup
            layers={sorted.layers}
            onClick={(layer) => clickLayer(layer)}
          />
        </Card>
      ))}
    </React.Fragment>
  );
}

export default LayerSelection;
